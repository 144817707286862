import { getLocations } from '@vakantiesnl/components/src/utils/geo/getLocations';
import { getMicroCopy } from '@vakantiesnl/services/src/microcopy';
import { Nbc, Search } from '@vakantiesnl/types';

import data from '@MicroCopiesNL';

import { mapOffers } from './mapper';
import {
	mapContactInformation,
	mapDescription,
	mapFacts,
	mapGeoCoordinates,
	mapMerchandisingLabel,
	mapNbcImages,
	mapTopImages,
} from '../nbc/mapper';

export function mapCompactAccommodations(raw: Search.RawCompactAccommodationItem[]): Search.CompactAccommodationItem[] {
	return raw.map((a) => mapCompactAccommodation(a.giata_id, a.nbc?.accommodation, a.offers));
}

// used flash deals + similar accos, + offers
export function mapCompactAccommodation(
	giataId: number | undefined,
	accommodation: Nbc.RawCompactAccommodationNbcItem | undefined,
	offers: Search.RawOfferItem[] | undefined,
): Search.CompactAccommodationItem {
	return {
		id: accommodation?.id ?? '',
		giataId: giataId,
		heliosId: accommodation?.entity_id ?? 0,
		goalId: accommodation?.goal_id ?? null,
		slug: accommodation?.slug ?? '',
		fullSlugWithType: `/${accommodation?.full_slug_with_acco_type}`,
		name: accommodation?.name ?? '',
		title: accommodation?.name ?? '',
		isBookable: accommodation?.is_bookable ?? false,
		isBookableQenner: Boolean(accommodation?.is_bookable_qenner),
		starRating: Math.round(accommodation?.star_rating ?? 0),
		rating: Math.round((accommodation?.review_calculations?.overall?.rating ?? 0) * 10) / 10,
		reviewCount: accommodation?.review_count ?? 0,
		reviewPerAspectGroup: accommodation?.review_calculations?.perAspectGroup ?? {},
		country: accommodation?.country ?? '',
		region: accommodation?.region ?? '',
		city: accommodation?.city ?? '',
		type: accommodation?.acco_type ?? '',
		formattedType: getMicroCopy(`accoType.${accommodation?.acco_type}`, data),
		merchandisingLabel: accommodation?.merchandising_label
			? mapMerchandisingLabel(accommodation.merchandising_label)
			: undefined,
		previewImage: accommodation?.preview_image ? accommodation.preview_image : undefined,
		topImages: mapTopImages(accommodation?.top_images ?? []),
		awards: accommodation?.awards ?? {},
		parents: accommodation?.parents ?? [],
		location: getLocations(accommodation?.parents),
		offers: mapOffers(offers ?? []),
		chain: accommodation?.chain ?? null,
	};
}

export function mapFullAccommodations(raw: Search.RawFullAccommodationItem[]): Search.FullAccommodationItem[] {
	return raw.map(mapFullAccommodation);
}

export function mapFullAccommodation(raw: Search.RawFullAccommodationItem): Search.FullAccommodationItem {
	return {
		...mapCompactAccommodation(raw.giata_id, raw.nbc?.accommodation, raw.offers),
		contactInformation: mapContactInformation(raw.nbc as Nbc.RawFullNbcItem),
		description: mapDescription(raw.nbc?.accommodation?.description),
		distanceFilters: raw.nbc?.accommodation?.distance_filters ?? [],
		facts: raw.nbc ? mapFacts(raw.nbc) : undefined,
		filters: raw.nbc?.accommodation?.filters ?? [],
		geo: mapGeoCoordinates(raw.nbc as Nbc.RawFullNbcItem),
		countryCode: raw.nbc?.accommodation?.country_code ?? '',
		images: (raw.nbc?.accommodation?.images ?? []).map(mapNbcImages),
		premiumAdlinkEnabled: raw.nbc?.accommodation?.premium_ad_link_enabled ?? false,
		premiumAdlinkUrl: raw.nbc?.accommodation?.premium_ad_link_url ?? null,
		source: raw.nbc?.accommodation?.source,
	};
}
