/* istanbul ignore file */
import { sortObject } from '@vakantiesnl/services/src/util/sortObjectKeys';
import { Search } from '@vakantiesnl/types';
import { isObject } from 'lodash';

import { SearchBody } from './search-body';
import { useTripFilterContext } from '../context/useTripFilterContext';
import { usePartyComposition } from '../stores/globalFiltersStore';
import { getDefaultDate } from '../util/getDefaultDate';

export const CITY_TRIP_OFFSET = 100;

export const createGeoContextRequest = (body: Search.VaknlSearchBody): RequestInfo => {
	const sortedBody = JSON.stringify(sortObject(body));

	return new Request(`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/geo-context-filters`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: sortedBody,
	});
};

export const createVaknlSearchRequest = (body: Search.VaknlSearchBody): RequestInfo => {
	return new Request(`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/search`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	});
};

export type OfferResponse = {
	brand: string;
	currency: string;
	lang: string;
	results_total: number;
	results_per_page: number;
	showing_results_from: number;
	type: string;
	accommodations: Search.RawCompactAccommodationItem[];
	party: Search.RawParty;
};

export const useCreateAccommodationOffersRequestBody = (): string => {
	const {
		state,
		computed: { durations },
	} = useTripFilterContext();

	const party = usePartyComposition();

	const searchBody = SearchBody.createDepartureDateRequest(party)
		.appendDepartureDate(state.departureDate?.[0] || getDefaultDate())
		.appendDurationsFlight(durations.map((value) => value - 1))
		.appendDepartureAirports(state.airports)
		.appendMealplans(state.mealplans)
		.appendAirlines(state.airlines)
		.appendFlightProvider(state.flightProvider)
		.appendTourOperators(state.tourOperators)
		.appendMinPrice(state.minPrice)
		.appendMaxPrice(state.maxPrice)
		.toString();

	return searchBody;
};

export type FlightAlternativesRequestProps = {
	giataId: number;
	departureDate?: string;
	departureAirport?: string | string[];
	duration?: number;
	mealplans?: string[];
	airlines?: string[];
	flightProvider?: string;
	tourOperators?: string[];
	minPrice?: string;
	maxPrice?: string;
};

export const useCreateFlightAlternativesRequestBody = (offer?: Search.OfferItem): string => {
	const { state } = useTripFilterContext();
	const party = usePartyComposition();

	const searchBody = SearchBody.createFlightAlternativesRequest(party)
		.appendDepartureDate(offer?.meta?.departureDate)
		.appendDurationAccommodation(offer?.durationNights)
		.appendDepartureAirport(state.airports)
		.appendMealplans(state.mealplans)
		.appendAirlines(state.airlines)
		.appendFlightProvider(state.flightProvider)
		.appendTourOperators(state.tourOperators)
		.appendMinPrice(state.minPrice)
		.appendMaxPrice(state.maxPrice)
		.toString();

	return searchBody;
};

export type FlightAlternativesResponse = {
	results_total: number;
	results_per_page: number;
	results_from: number;
	accommodations: Search.RawCompactAccommodationItem[];
};

export type RoomOffersRequestProps = {
	giataId: number;
	departureDate?: string;
	duration: number;
	departureAirport: string;
	arrivalAirport: string;
	tourOperator: string;
	inboundFlightCode: string;
	outboundFlightCode: string;
	inboundFlightId?: string;
	outboundFlightId?: string;
	mealplans?: string[];
	airlines?: string[];
	flightProvider?: string;
	minPrice?: string;
	maxPrice?: string;
};

export const useCreateRoomOffersRequest = (offer?: Search.OfferItem): string => {
	const { state } = useTripFilterContext();
	const party = usePartyComposition();

	const searchBody = SearchBody.createRoomTypeRequest(party)
		.appendDepartureDate(offer?.meta?.departureDate)
		.appendDurationAccommodation(offer?.durationNights)
		.appendDepartureAirport(offer?.outbound.departureAirport.code)
		.appendArrivalAirport(offer?.outbound.arrivalAirport.code)
		.appendTourOperator(offer?.meta?.brand || '')
		.appendInboundFlightId(offer?.inbound.meta?.flightId)
		.appendOutboundFlightId(offer?.outbound.meta?.flightId)
		.appendInboundFlightCode(offer?.inbound.meta?.flightCode || '')
		.appendOutboundFlightCode(offer?.outbound.meta?.flightCode || '')
		.appendMealplans(state.mealplans)
		.appendAirlines(
			offer?.outbound.airline?.code && offer?.inbound.airline?.code
				? // Make list unique
					[offer?.outbound.airline?.code, offer?.inbound.airline?.code].filter((x, i, a) => a.indexOf(x) == i)
				: [],
		)
		.appendFlightProvider(offer?.outbound.meta?.provider)
		.appendMinPrice(state.minPrice)
		.appendMaxPrice(state.maxPrice)
		.toString();

	return searchBody;
};

export type MealPlanOfferRequestProps = {
	giataId: number;
	departureDate?: string;
	duration: number;
	departureAirport: string;
	arrivalAirport: string;
	tourOperator: string;
	inboundFlightCode: string;
	outboundFlightCode: string;
	inboundFlightId?: string;
	outboundFlightId?: string;
	provider: string;
	roomsCodes: string[][];
	roomsKeys?: string[];
	mealplans?: string[];
	airlines?: string[];
	flightProvider?: string;
	opCodes?: string[];
	minPrice?: string;
	maxPrice?: string;
};

export const useCreateMealPlanOffersRequest = (offer?: Search.OfferItem): string => {
	const party = usePartyComposition();
	const { state } = useTripFilterContext();

	if (!offer) {
		return '';
	}

	const searchBody = SearchBody.createMealplanRequest(party)
		.appendDepartureDate(offer.meta?.departureDate)
		.appendDurationAccommodation(offer.durationNights)
		.appendDepartureAirport(offer.outbound.departureAirport.code)
		.appendArrivalAirport(offer.outbound.arrivalAirport.code)
		.appendTourOperator(offer.meta?.brand || '')
		.appendInboundFlightId(offer.inbound.meta?.flightId)
		.appendOutboundFlightId(offer.outbound.meta?.flightId)
		.appendInboundFlightCode(offer.inbound.meta?.flightCode || '')
		.appendOutboundFlightCode(offer.outbound.meta?.flightCode || '')
		.appendProvider(offer.meta?.provider)
		.appendRoomsCodes(offer.rooms.map((room) => room.codes))
		.appendRoomsBookingCodes(offer.rooms.map((room) => room.opCode))
		.appendRoomsKeys(
			offer.rooms.reduce((acc: string[], room) => (room.roomKey !== null ? [...acc, room.roomKey] : acc), []),
		)
		.appendMealplans(state.mealplans)
		.appendAirlines(
			offer.outbound.airline?.code && offer.inbound.airline?.code
				? // Make list unique
					[offer.outbound.airline?.code, offer.inbound.airline?.code].filter((x, i, a) => a.indexOf(x) == i)
				: [],
		)
		.appendFlightProvider(offer.outbound.meta?.provider)
		.appendMinPrice(state.minPrice)
		.appendMaxPrice(state.maxPrice)
		.toString();

	return searchBody;
};

export const createZooverCompositeSearchRequest = (body: Search.ZooverCompositeSearchBody | string): RequestInfo => {
	const requestBody = isObject(body) ? JSON.stringify(sortObject(body)) : body;
	return new Request(`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/composite-search`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: requestBody,
	});
};
