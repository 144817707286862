import { getMicroCopy } from '@vakantiesnl/services/src/microcopy';
import { Nbc, Geo, Elastic, Search } from '@vakantiesnl/types';

import microCopies from '@MicroCopiesNL';

import { groupFacilities } from './getInformation';

export const mapMerchandisingLabel = (raw: Nbc.MerchandisingEnum): Nbc.MerchandisingType => {
	switch (raw) {
		case Nbc.MerchandisingEnum.bestseller:
			return 'bestseller';
		case Nbc.MerchandisingEnum.best_reviewed:
			return 'best_reviewed';
		case Nbc.MerchandisingEnum.boutique:
			return 'boutique';
		case Nbc.MerchandisingEnum.adult_only:
			return 'adult_only';
		case Nbc.MerchandisingEnum.child_friendly:
			return 'child_friendly';
		case Nbc.MerchandisingEnum.luxe:
			return 'luxe';
		case Nbc.MerchandisingEnum.city_trip:
			return 'city_trip';
		case Nbc.MerchandisingEnum.distant_destination:
			return 'distant_destination';
		case Nbc.MerchandisingEnum.all_inclusive:
			return 'all_inclusive';
		case Nbc.MerchandisingEnum.wellness:
			return 'wellness';
		case Nbc.MerchandisingEnum.sun_holiday:
			return 'sun_holiday';
		case Nbc.MerchandisingEnum.budget:
			return 'budget';
		default:
			return 'aqua_park';
	}
};

export const mapNbcImages = (rawImage: Nbc.RawImage): Nbc.Image => ({
	rotationDegrees: rawImage.rotation,
	id: rawImage.id,
	order: rawImage.order,
	url: rawImage.filename,
	bucketUrl: rawImage.filename,
	category: rawImage.category,
	source: rawImage.source,
});

export const mapGeoCoordinates = ({ accommodation }: Nbc.RawFullNbcItem): Geo.Coordinates => ({
	longitude: accommodation?.geo?.coordinates?.[0],
	latitude: accommodation?.geo?.coordinates?.[1],
});

export const formatContactInfoUrl = (
	contact_information: Nbc.RawContactInformation | undefined,
): string | undefined => {
	if (!contact_information?.url) return undefined;

	return /^(http:\/\/|https:\/\/)/.test(contact_information?.url)
		? contact_information?.url
		: `http://${contact_information?.url}`;
};

export const mapContactInformation = ({ accommodation }: Nbc.RawFullNbcItem): Nbc.ContactInformation => ({
	phone: accommodation?.contact_information?.phone,
	email: accommodation?.contact_information?.email,
	address: accommodation?.address,
	socialLinks: accommodation?.contact_information?.social_links,
	formattedUrl: formatContactInfoUrl(accommodation?.contact_information),
});

export const mapFacts = ({ accommodation }: Nbc.RawFullNbcItem): Nbc.Facts | Elastic.FormattedFacts[] | undefined => {
	if (!accommodation?.facts) return undefined;

	if (typeof accommodation.facts === 'string') return JSON.parse(accommodation.facts.toString());
	else if (Array.isArray(accommodation.facts)) return groupFacilities(accommodation.facts);
};

export const formatAccommodationType = ({ accommodation }: Nbc.RawFullNbcItem): string | undefined => {
	if (!accommodation?.acco_type || !microCopies) return undefined;

	return getMicroCopy(`accoType.${accommodation.acco_type}`, microCopies);
};
export const formatAccommodationTitle = (accoName?: string, accoType?: string): string | undefined => {
	if (!accoType || !accoName || !microCopies) return undefined;

	return `${getMicroCopy(`accoType.${accoType}`, microCopies)} ${accoName}`;
};

export const mapDescription = (rawDescriptionString: string | undefined | null): Nbc.Description | undefined => {
	if (!rawDescriptionString) return undefined;

	let paragraphs: Nbc.ParagraphSection[];
	let headerDescription = '';
	let headerTitle = '';
	const rawDescription = JSON.parse(rawDescriptionString) as Nbc.RawDescription;

	paragraphs = rawDescription?.paragraphs
		? rawDescription.paragraphs
		: (JSON.parse(rawDescriptionString) as Nbc.ParagraphSection[]);

	if (typeof paragraphs == 'string') paragraphs = JSON.parse(paragraphs);

	if (paragraphs?.length > 0) {
		headerTitle = paragraphs[0].title;
		headerDescription = paragraphs[0].text;
		paragraphs.shift();
	}

	return {
		paragraphs: paragraphs,
		headerTitle: headerTitle,
		headerDescription: headerDescription,
	};
};

export const mapTopImage = (raw: Search.RawTopImageItem): Search.TopImageItem => {
	return {
		id: raw.id,
		url: raw.sourceUrl || raw.filename || '',
		rotationDegrees: raw.rotationDegrees || raw.rotation || 0,
	};
};

export const mapTopImages = (raw: Search.RawTopImageItem[]): Search.TopImageItem[] => raw.map(mapTopImage);
