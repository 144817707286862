export const createRequestByGiataId = (giataId: string, returnUnpublishedAccoDetails = false): RequestInfo =>
	new Request(
		`${process.env.NEXT_PUBLIC_NBC_ENDPOINT_URL}/api/v1/accommodations?giata_id=${giataId}${returnUnpublishedAccoDetails ? '&return_unpublished_acco_details=true' : ''}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'vaknl-origin-service': 'frontend',
				Brand: process.env.NEXT_PUBLIC_BRAND || '',
			},
		},
	);

/** $userGeneratedContent is used to conditionally fetch UGC from the NBC service  */
export const createRequestByEntityId = (
	entityId: string,
	userGeneratedContent = false,
	returnUnpublishedAccoDetails = false,
): RequestInfo =>
	new Request(
		`${process.env.NEXT_PUBLIC_NBC_ENDPOINT_URL}/api/v1/accommodations?entity_id=${entityId}${
			userGeneratedContent ? '&include_ugc_content=true' : ''
		}${returnUnpublishedAccoDetails ? '&return_unpublished_acco_details=true' : ''}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'vaknl-origin-service': 'frontend',
				Brand: process.env.NEXT_PUBLIC_BRAND || '',
			},
		},
	);

/** $userGeneratedContent is used to conditionally fetch UGC from the NBC service  */
export const createRequestBySlug = (slug: string, userGeneratedContent?: boolean): RequestInfo =>
	new Request(
		`${process.env.NEXT_PUBLIC_NBC_ENDPOINT_URL}/api/v1/accommodations?slug=${slug}${
			userGeneratedContent ? '&include_ugc_content=true' : ''
		}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'vaknl-origin-service': 'frontend',
				Brand: process.env.NEXT_PUBLIC_BRAND || '',
			},
		},
	);

export const createCitiesRequestByEntityId = (entityId: string): RequestInfo =>
	new Request(`${process.env.NEXT_PUBLIC_NBC_ENDPOINT_URL}/api/v1/destinations/cities/${entityId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'vaknl-origin-service': 'frontend',
			Brand: process.env.NEXT_PUBLIC_BRAND || '',
		},
	});
