export const FACILITY_ORDER: Record<string, string[]> = {
	Strand: [
		'Soort strand',
		'Afstand tot strand',
		'Kleur van het strand',
		'Privé strand',
		'Directe toegang vanuit accommodatie',
		'Strand toegang',
		'Strand faciliteiten',
	],
	Zwembad: ['Zwembad', 'Waterpark', 'Aantal glijbanen'],
	'Algemene informatie': [
		'Aantal sterren',
		'Verzorging',
		'Adults only',
		'Huisdieren',
		'Voorzieningen voor minder validen',
		'Aantal kamers',
		'Check-in',
		'Check-out',
		'Shuttle bus vanaf vliegveld',
		'De staf spreekt',
		'Roken',
		'Eten en drinken',
		'BBQ',
		'Minimale leeftijd gasten',
		'Aantal personen',
		'Aantal kamers in vakantiehuis',
		'Aantal campingplaatsen',
		'Naturistencamping',
		'Mobilehomes aanwezig',
		'Schaduwplaatsen aanwezig',
		'Geldautomaat in directe omgeving (1 km)',
	],
	'Families en kinderen': ['Animatie programma', 'Aanbod voor kinderen', 'Kinderopvang', 'Verblijf voor kinderen'],
	Kamerfaciliteiten: ['Temperatuurregeling', 'Balkon/terras', 'Jacuzzi', 'Keuken', 'Huishoudelijke apparatuur'],
	Wellness: ['Sauna', 'Wellness behandelingen'],
	Wifi: ['Wifi', 'Gratis wifi', 'Betaalde wifi'],
	'Restaurant & Bar': ['Restaurant', 'Soort gerechten', 'Bar / kantine', 'Aantal cafés', 'snackbar'],
	Parkeren: ['Parkeren', 'Reservering voor parkeren', 'Parkeermogelijkheden'],
	'Overige faciliteiten': [
		'Airco',
		'Winkels',
		'Lift',
		'Receptie',
		'internet',
		'Room Service',
		'Op het terrein',
		'Entertainment',
		'Centrale kookgelegenheid',
		'prive sanitair mogelijk',
		'warme douches',
		'Overige services',
	],
	Activiteiten: [
		'Sportfaciliteiten',
		'Watersport',
		'Buitensporten',
		'Balsporten',
		'Fitness',
		'Tennisbaan',
		'Tennisbaan informatie',
		'Sport & Spel',
		'Wintersport',
	],
	Afstanden: [
		'Afstand tot centrum',
		'Afstand tot openbaar vervoer',
		'Afstand tot vliegveld',
		'Rijtijd tot vliegveld',
		'Afstand tot snelweg',
	],
};

export const CATEGORY_ORDER: string[] = [
	'Adresgegevens',
	'Algemene informatie',
	'Zwembad',
	'Strand',
	'Families en kinderen',
	'Restaurant & Bar',
	'Wifi',
	'Kamerfaciliteiten',
	'Overige faciliteiten',
	'Wellness',
	'Parkeren',
	'Afstanden',
	'Activiteiten',
];

export const GROUP_ORDER: string[] = [
	'hotel-type',
	'building-information',
	'camping-information',
	'payment',
	'facilities',
	'room-facilities',
	'sport-entertainment',
	'hygiene',
	'meals',
	'beach',
	'distances',
];
